//This is the Auth token, you will use it to generate a meeting and connect to it
export const authToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcGlrZXkiOiJlMGM2ZTEwMi1jZDMwLTQwMzItYTkyNy0wNDU1MmJmYTQ4NTMiLCJwZXJtaXNzaW9ucyI6WyJhbGxvd19qb2luIl0sImlhdCI6MTczMDE4ODE5MywiZXhwIjoxODg3OTc2MTkzfQ.Icfm7b3q9jI5-Ogcs1a1S2LBDAJFffmI3nldij0smDw"
// API call to create a meeting
export const createMeeting = async () => {
    const res = await fetch(`https://api.videosdk.live/v2/rooms`, {
        method: "POST",
        headers: {
            authorization: `${authToken}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
    });
    //Destructuring the roomId from the response
    return res.json();
};