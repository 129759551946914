import React, { useState } from "react";
import {
    useMeeting,
} from "@videosdk.live/react-sdk";
import Controls from "./Controls";
import ParticipantView from "./ParticipantView";
import PresenterView from "./PresenterView";
import { RxLink1 } from "react-icons/rx";
import { RxCross2 } from "react-icons/rx";
import { AiOutlineExpandAlt } from "react-icons/ai";
import logo from "./img/logo.png";
import { Alert } from "reactstrap";
function MeetingView(props) {
    const [mini, setMinimize] = useState(false);
    const [max, setMaximize] = useState(false);
    const { presenterId } = useMeeting();
    const [joined, setJoined] = useState(null);
    const [screenshared, setScreenShare] = useState(null)
    const [notification, setNotification] = useState("");
    const [showNotification, setShowNotification] = useState(false);
    const { join, participants, leave } = useMeeting({
        onPresenterChanged,
        //callback for when meeting is joined successfully
        onMeetingJoined: () => {
            setJoined("JOINED");
        },
        //callback for when meeting is left
        onMeetingLeft: () => {
            props.onMeetingLeave();
        },
        //callback for when there is an error in a meeting
        onError: (error) => {
            setNotification(error.message);
            setShowNotification(true);
            setTimeout(() => setShowNotification(false), 5000);
        },
        onMeetingStateChanged: (data) => {
            const { state } = data;
            if (state == 'FAILED') {
                setNotification("Meeting is Connected");
                setShowNotification(true);
                setTimeout(() => setShowNotification(false), 5000);
            } else if (state == 'DISCONNECTED') {
                setNotification("Meeting connection disconnected abruptly");
                setShowNotification(true);
                setTimeout(() => setShowNotification(false), 5000);
            } else if (state == 'CLOSING') {
                setNotification("Meeting is closing");
                setShowNotification(true);
                setTimeout(() => setShowNotification(false), 5000);
            } else if (state == 'CLOSED') {
                setNotification("Meeting connection closed");
                setShowNotification(true);
                setTimeout(() => setShowNotification(false), 5000);
            }
        }
    });

    //Callback for when the presenter changes
    function onPresenterChanged(presenterId) {
        if (presenterId) {
            console.log(presenterId, "started screen share");
            setScreenShare(presenterId)
        } else {
            setScreenShare(null)
            console.log("someone stopped screen share");
        }
    }

    //Get the method which will be used to join the meeting.
    //We will also get the participants list to display all participants

    const joinMeeting = () => {
        setJoined("JOINING");
        join();
    };

    const applyClass = () => {
        if (mini) {
            return 'minimize'
        } else if (max) {
            return 'maximize'
        }

        return 'shareModal'
    }

    return (
        <div className={`${applyClass()}`}>
            <div className="flexflow">
                {props.meetingId && joined && joined === "JOINED" ?
                    <img src={logo} width={98} alt={''} style={{ marginLeft: '10px' }} /> : null}
                <div class="text-right-full">
                    <AiOutlineExpandAlt className="iconsss" onClick={() => { setMinimize(max ? false : mini); setMaximize(!max) }} />
                    <RxCross2 className="iconsss" onClick={props.onMeetingLeave} />
                </div>
            </div>

            {showNotification && (
                <Alert color="danger" style={{ zIndex: "4000", width: '300px', marginLeft: '28px' }}>
                    {notification}
                </Alert>
            )}

            {(!joined || (joined !== "JOINING" && joined !== "JOINED")) ? <h2 className="labeleHeading">Ready to Join?</h2> : null}

            {applyClass() !== 'maximize' ?
                (props.meetingId && joined && joined === "JOINED" ? <div className="callconnected">Call Connected</div>
                    : null) : null}
            {applyClass() !== 'maximize' ? <br /> : null}
            {applyClass() !== 'maximize' ? (<div className="mtc-2"><span class="meetingId">Meeting ID</span>
                <span class="meetingLink">{props.meetingId} <RxLink1 style={{ fontWeight: 'bolder' }} /></span>
            </div>) : null}
            {joined && joined === "JOINED" ? (
                <div>
                    {[...participants.keys()].map((participantId) => (
                        <ParticipantView
                            participantId={participantId}
                            key={participantId}
                        />
                    ))}
                </div>
            ) : joined && joined === "JOINING" ? (
                <p></p>
            ) : (
                <div style={{ textAlign: 'center', marginTop: '40px', marginBottom: '20px' }}>
                    <button className="joinnow" onClick={joinMeeting}>Join Now</button>
                </div>
            )}

            {<PresenterView presenterId={presenterId} />}
            {joined && joined == "JOINED" ?
                <Controls screenshared={screenshared} /> : null}
        </div>
    );
}

export default MeetingView;