import { useState, useEffect } from "react";
import { useMeeting } from "@videosdk.live/react-sdk";

// images
import { PiMicrophoneFill } from "react-icons/pi";
import { PiPhoneDisconnect } from "react-icons/pi";
import { PiMicrophoneSlashFill } from "react-icons/pi";
import { PiArrowSquareUp } from "react-icons/pi";

function Controls(props) {
    const { leave, toggleMic, toggleWebcam, toggleScreenShare } = useMeeting();
    const [micState, setMicState] = useState(true);
    const [cameraState, setCameraState] = useState(false);
    const [screenState, setScreenState] = useState(false);

    useEffect(() => {
        if (cameraState && screenState) {
            updateCameraState()
        }
    }, [cameraState, screenState])

    const updateMicState = () => {
        setMicState(!micState);
        toggleMic();
    }

    const updateCameraState = () => {
        setCameraState(!cameraState);
        toggleWebcam();
    }

    const updateScreenState = () => {
        setScreenState(!screenState);
        toggleScreenShare();
    }
    return (
        <div className="flex d-flex sharecontrols">
            {
                micState ?
                    (<span onClick={() => updateMicState()}>
                        <PiMicrophoneFill className="chat-icon-size " />
                    </span>) :
                    (<span onClick={() => updateMicState()}>
                        <PiMicrophoneSlashFill className="chat-icon-size " />
                    </span>)
            }

            {
                screenState ?
                    (<span onClick={() => updateScreenState()}>
                        <PiArrowSquareUp className="chat-icon-size " />
                    </span>) :
                    (<span onClick={() => updateScreenState()} className={!props.screenshared ? '' : 'pointernone'}>
                        <PiArrowSquareUp className={!props.screenshared ? 'chat-icon-size' : 'disablebut'} />
                    </span>)
            }


            <span onClick={() => leave()}>
                <PiPhoneDisconnect className="chat-icon-black" />
            </span>
        </div >
    );
}

export default Controls