import React, { useEffect, useMemo, useRef, useState } from "react";
import {
    MeetingProvider,
    MeetingConsumer,
    useMeeting,
    useParticipant,
} from "@videosdk.live/react-sdk";
import { authToken, createMeeting } from "./API";
const ReactPlayer = React.lazy(() => import('react-player'));

function ParticipantView(props) {
    const { join, participants } = useMeeting();
    //Callback for when the participant starts a stream
    function onStreamEnabled(stream) {
        if (stream.kind === 'share') {
            console.log("Share Stream On: onStreamEnabled", stream);
        }
    }

    //Callback for when the participant stops a stream
    function onStreamDisabled(stream) {
        if (stream.kind === 'share') {
            console.log("Share Stream Off: onStreamDisabled", stream);
        }
    }

    const micRef = useRef(null);
    const { webcamStream, micStream, webcamOn, micOn, isLocal, displayName } =
        useParticipant(props.participantId, {
            onStreamEnabled,
            onStreamDisabled
        });

    const videoStream = useMemo(() => {
        if (webcamOn && webcamStream) {
            const mediaStream = new MediaStream();
            mediaStream.addTrack(webcamStream.track);
            return mediaStream;
        }
    }, [webcamStream, webcamOn]);

    useEffect(() => {
        if (micRef.current) {
            if (micStream) {
                const mediaStream = new MediaStream();
                mediaStream.addTrack(micStream.track);

                micRef.current.srcObject = mediaStream;
                micRef.current
                    .play()
                    .catch((error) =>
                        console.error("videoElem.current.play() failed", error)
                    );
            } else {
                //  micRef.current.srcObject = null;
            }
        }
    }, [micStream, micOn]);

    /* useEffect(() => {
        if (micRef.current) {
            participants.forEach((participant) => {
                participant.streams.values().forEach((res, ke) => {
                    if (true) {
                        participant.enableMic()
                        const mediaStream = new MediaStream();
                        mediaStream.addTrack(res.track);

                        micRef.current.srcObject = mediaStream;
                        micRef.current
                            .play()
                            .catch((error) =>
                                console.error("videoElem.current.play() failed", error)
                            );
                    }
                })
            });
        }
    }, [micStream, micOn, participants]); */


    return (
        <div style={{ textAlign: 'center', width: "calc(100% - 75px)" }}>
            <audio ref={micRef} autoPlay playsInline muted={isLocal} />
            {webcamOn && (
                <ReactPlayer
                    //
                    playsinline // extremely crucial prop
                    pip={false}
                    light={false}
                    controls={false}
                    muted={false}
                    playing={true}
                    //
                    url={videoStream}
                    width={'100%'}
                    onError={(err) => {
                        console.log(err, "participant video error");
                    }}
                />
            )}
        </div>
    );
}

export default ParticipantView;