import React, { useState, useEffect } from "react";
import {
  MeetingProvider,
} from "@videosdk.live/react-sdk";
import { authToken, createMeeting } from "./API";
import JoinScreen from './JoinScreen';
import MeetingView from "./MeetingView";

function VideoApp(props) {
  const [meetingId, setMeetingId] = useState(null);

  useEffect(() => {
    if (props.meetingId) {
      setMeetingId(props.meetingId)
    }
  }, [props.meetingId])

  const onMeetingLeave = () => {
    setMeetingId(null);
    props.toggleMeeting();
  };

  return authToken && meetingId ? (
    <MeetingProvider
      config={{
        meetingId: meetingId,
        micEnabled: true,
        webcamEnabled: false,
      }}
      token={authToken}

    >
      <MeetingView meetingId={meetingId} onMeetingLeave={onMeetingLeave} />
    </MeetingProvider>
  ) : null;
}

export default VideoApp;