import React, { useEffect, useMemo, useRef } from "react";
import {
    useParticipant,
} from "@videosdk.live/react-sdk";
const ReactPlayer = React.lazy(() => import('react-player'));

const PresenterView = ({ presenterId }) => {

    const { screenShareAudioStream, isLocal, screenShareStream, screenShareOn, micStream } =
        useParticipant(presenterId);
    //Creating a media stream from the screen share stream
    const mediaStream = useMemo(() => {
        if (screenShareOn && screenShareStream) {
            const mediaStream = new MediaStream();
            mediaStream.addTrack(screenShareStream.track);
            return mediaStream;
        }
    }, [screenShareStream, screenShareOn]);


    // Creating a reference to the audio element
    const audioPlayer = useRef();

    // Playing the screen share audio stream
    useEffect(() => {
        if (
            //    !isLocal &&
            audioPlayer.current &&
            //     screenShareOn &&
            screenShareAudioStream
        ) {
            const mediaStream = new MediaStream();
            mediaStream.addTrack(screenShareAudioStream.track);

            audioPlayer.current.srcObject = mediaStream;
            audioPlayer.current.play().catch((err) => {
                if (
                    err.message ===
                    "play() failed because the user didn't interact with the document first. https://goo.gl/xX8pDD"
                ) {
                    console.error("audio" + err.message);
                }
            });
        } else {
            // audioPlayer.current.srcObject = null;
        }
    }, [screenShareAudioStream, screenShareOn, isLocal]);

    useEffect(() => {
        if (audioPlayer.current) {
            if (micStream) {
                const mediaStream = new MediaStream();
                mediaStream.addTrack(micStream.track);

                audioPlayer.current.srcObject = mediaStream;
                audioPlayer.current
                    .play()
                    .catch((error) =>
                        console.error("videoElem.current.play() failed", error)
                    );
            }
        }
    }, [audioPlayer, micStream])

    return (
        <>
            <ReactPlayer
                playsinline
                playIcon={<>play</>}
                pip={false}
                light={false}
                controls={false}
                muted={false}
                playing={true}

                url={mediaStream}
                width={"100%"}

                height={"auto"}
                onError={(err) => {
                    console.log(err, "presenter video error");
                }}
            />
            <audio autoPlay playsInline controls={false} muted={isLocal} ref={audioPlayer} />
        </>
    );
};

export default PresenterView;